import {$KTM, BaseListItem, BaseListItemsLoader, KeyMetadatas, TypedObject} from "../shared/types";
import {SvgIcon} from "@mui/material";
import {FavoriteBorderOutlined, SettingsOutlined, StarOutlineOutlined} from "@mui/icons-material";
import {nightGray} from "../shared/colors";
import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../shared/json/json-property";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import {JSON_OBJECT, Type} from "../shared/json/helpers";
import {Api, ApiStation} from "./api";
import {FormGen} from "../shared/formgen";
import {COLOR_PRIMARY} from "./styles";

export type StationBase = {
  type: "station" | "group",
  id: string,
  name: string,
  image: string,
  country?: string,
  websiteUrl?: string,
  supportUrl?: string,
}

export type Station = StationBase & {
  description: string,
  streamUrls: string[],
}

export type StationGroup = StationBase & {
  list: Station[],
}

export function stationFromApiStation(station: ApiStation): Station {
  return {
    type: "station",
    id: station.stationuuid,
    name: station.name,
    image: station.favicon,
    description: station.country,
    streamUrls: [station.url_resolved],
    websiteUrl: station.homepage,
  };
}

export const TOP_PICKS_STATIONS: (Station | StationGroup)[] = [
  // {
  //   type: "station",
  //   id: "kfk",
  //   name: "Keys for Kids",
  //   image: "/stations/kfk.webp",
  //   description: "",
  //   streamUrls: ["https://streaming.live365.com/a78246"],
  //   websiteUrl: "",
  //   supportUrl: "https://keysforkids.org",
  // },
  // {
  //   type: "station",
  //   id: "kidsradio",
  //   name: "Kids Radio",
  //   image: "/stations/kidsradio.jpg",
  //   description: "Greece",
  //   streamUrls: ["https://stream.radiojar.com/gngfpx33hwzuv"],
  //   websiteUrl: "",
  //   supportUrl: "https://kidsradio.com",
  // },
];

export enum Tab {
  TOP_PICKS = "top_picks",
  FAVORITES = "favorites",
  SETTINGS = "settings",
}

export type TabMetadata = {
  title: string,
  iconName: string,
}
export const TABS_KTM = new KeyMetadatas<TabMetadata>([
  $KTM<TabMetadata>(Tab.TOP_PICKS, {
    title: "Top Picks",
    iconName: "star",
  }),
  $KTM<TabMetadata>(Tab.FAVORITES, {
    title: "Favorites",
    iconName: "favorite",
  }),
  $KTM<TabMetadata>(Tab.SETTINGS, {
    title: "Settings",
    iconName: "settings",
  }),
])

export class Stations {

  private static instance: Stations;

  static getInstance(): Stations {
    if (!this.instance) {
      this.instance = new Stations();
    }
    return this.instance;
  }

  async getStationById(id: string): Promise<Station> {
    for (const base of TOP_PICKS_STATIONS) {
      if (base.type === "station" && base.id === id) {
        return base as Station;
      } else if (base.type === "group") {
        for (const station of (base as StationGroup).list) {
          if (station.id === id) {
            return station;
          }
        }
      }
    }
    const apiStation = await Api.getInstance().searchRadioBrowserStationByUuid(id);
    if (apiStation) {
      return stationFromApiStation(apiStation);
    }
    return null;
  }
}

@JsonObject()
export class Favorite extends BaseListItem {

  @JsonProperty()
  readonly stationId: string;

  static createNew(stationId: string): Favorite {
    return new Favorite(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), stationId);
  }

  constructor(id: string, creator: string, created: number, stationId: string) {
    super(id, creator, created);
    this.stationId = stationId;
  }
}

export class Favorites extends BaseListItemsLoader<Favorite> {

  private static instance: Favorites;

  static getInstance(): Favorites {
    if (!this.instance) {
      this.instance = new Favorites();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "favorites";
  }

  async addListItem(item: Favorite): Promise<void> {
    return super.addListItem(item);
  }

  protected deserializeItem(value: any): Favorite {
    return JSON_OBJECT.deserializeObject(value, Favorite);
  }

  protected serializeItem(item: Favorite): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Favorite, item2: Favorite): number {
    return 0;
  }
}

@JsonObject()
export class Settings extends TypedObject {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = await Settings.load();
  }

  private readonly memberAuth = getMemberAuth();

  readonly id: string = "settings";

  @FormGen({
    name: "Automatically turn off after (hours)",
    description: "Turns off playback automatically after specified number of hours.",
    type: "number"
  })
  @JsonProperty()
  autoTurnOffHours: number = 0;

  constructor() {
    super(null, null);
  }

  static async load() {
    const obj = new Settings();
    if (!obj.memberAuth) {
      return null;
    }
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return Settings;
  }
}

export type AiriStations = {
  "stations": {
    "stationid": string,
    "stationname": string,
    "stationnamelocal": string,
    "stationdescription": string,
    "logoid": number,
    "logopath": string,
    "slogan": string,
    "isocountry": string,
    "flag": string,
    "tags": number[],
    "streams": string[],
  }[];
}
import {ReactElement} from "react";
import {colorGreen, colorRed} from "../shared/colors";

export type IconPropsBase = {
  height?: number,
  fillColor?: string,
  strokeWidth?: number,
}

const DEFAULT_SIZE = 40;

export function Star(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <path fill={props.fillColor || "none"} stroke="#111" stroke-linecap="round" stroke-linejoin="round"
          stroke-width={props.strokeWidth || 2.5}
          d="m13.728 3.444l1.76 3.549c.24.494.88.968 1.42 1.058l3.189.535c2.04.343 2.52 1.835 1.05 3.307l-2.48 2.5c-.42.423-.65 1.24-.52 1.825l.71 3.095c.56 2.45-.73 3.397-2.88 2.117l-2.99-1.785c-.54-.322-1.43-.322-1.98 0L8.019 21.43c-2.14 1.28-3.44.322-2.88-2.117l.71-3.095c.13-.585-.1-1.402-.52-1.825l-2.48-2.5C1.39 10.42 1.86 8.929 3.899 8.586l3.19-.535c.53-.09 1.17-.564 1.41-1.058l1.76-3.549c.96-1.925 2.52-1.925 3.47 0"
          color="currentColor"/>
  </svg>;
}

export function Favorite(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <path fill={props.fillColor || "none"} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width={props.strokeWidth || 2.5}
          d="M19.463 3.994c-2.682-1.645-5.023-.982-6.429.074c-.576.433-.864.65-1.034.65s-.458-.217-1.034-.65C9.56 3.012 7.219 2.349 4.537 3.994C1.018 6.153.222 13.274 8.34 19.284C9.886 20.427 10.659 21 12 21s2.114-.572 3.66-1.717c8.118-6.008 7.322-13.13 3.803-15.289"
          color="currentColor"/>
  </svg>
}

export function Play(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <path fill={props.fillColor || colorGreen} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width={props.strokeWidth || 2.5}
          d="M18.89 12.846c-.353 1.343-2.023 2.292-5.364 4.19c-3.23 1.835-4.845 2.752-6.146 2.384a3.25 3.25 0 0 1-1.424-.841C5 17.614 5 15.743 5 12s0-5.614.956-6.579a3.25 3.25 0 0 1 1.424-.84c1.301-.37 2.916.548 6.146 2.383c3.34 1.898 5.011 2.847 5.365 4.19a3.3 3.3 0 0 1 0 1.692"
          color="currentColor"/>
  </svg>
}

export function Stop(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <path fill={props.fillColor || colorRed} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width={props.strokeWidth || 2.5}
          d="M4 12c0-3.28 0-4.919.814-6.081a4.5 4.5 0 0 1 1.105-1.105C7.08 4 8.72 4 12 4s4.919 0 6.081.814a4.5 4.5 0 0 1 1.105 1.105C20 7.08 20 8.72 20 12s0 4.919-.814 6.081a4.5 4.5 0 0 1-1.105 1.105C16.92 20 15.28 20 12 20s-4.919 0-6.081-.814a4.5 4.5 0 0 1-1.105-1.105C4 16.92 4 15.28 4 12"
          color="currentColor"/>
  </svg>
}

export function Info(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <g fill={props.fillColor || "none"} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
       stroke-width={props.strokeWidth || 2.5}
       color="currentColor">
      <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10s10-4.477 10-10"/>
      <path d="M12.242 17v-5c0-.471 0-.707-.146-.854c-.147-.146-.382-.146-.854-.146m.75-3h.009"/>
    </g>
  </svg>
}

export function Settings(props: IconPropsBase): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.height || DEFAULT_SIZE} height={props.height || DEFAULT_SIZE}
              viewBox="0 0 24 24">
    <g fill={props.fillColor || "none"} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
       stroke-width={props.strokeWidth || 2.5}
       color="currentColor">
      <path
        d="M16.308 4.384c-.59 0-.886 0-1.155-.1l-.111-.046c-.261-.12-.47-.328-.888-.746c-.962-.962-1.443-1.443-2.034-1.488a2 2 0 0 0-.24 0c-.591.045-1.072.526-2.034 1.488c-.418.418-.627.627-.888.746l-.11.046c-.27.1-.565.1-1.156.1h-.11c-1.507 0-2.261 0-2.73.468s-.468 1.223-.468 2.73v.11c0 .59 0 .886-.1 1.155q-.022.057-.046.111c-.12.261-.328.47-.746.888c-.962.962-1.443 1.443-1.488 2.034a2 2 0 0 0 0 .24c.045.591.526 1.072 1.488 2.034c.418.418.627.627.746.888q.025.054.046.11c.1.27.1.565.1 1.156v.11c0 1.507 0 2.261.468 2.73s1.223.468 2.73.468h.11c.59 0 .886 0 1.155.1q.057.021.111.046c.261.12.47.328.888.746c.962.962 1.443 1.443 2.034 1.488q.12.009.24 0c.591-.045 1.072-.526 2.034-1.488c.418-.418.627-.626.888-.746q.054-.025.11-.046c.27-.1.565-.1 1.156-.1h.11c1.507 0 2.261 0 2.73-.468s.468-1.223.468-2.73v-.11c0-.59 0-.886.1-1.155q.021-.057.046-.111c.12-.261.328-.47.746-.888c.962-.962 1.443-1.443 1.488-2.034q.009-.12 0-.24c-.045-.591-.526-1.072-1.488-2.034c-.418-.418-.626-.627-.746-.888l-.046-.11c-.1-.27-.1-.565-.1-1.156v-.11c0-1.507 0-2.261-.468-2.73s-1.223-.468-2.73-.468z"/>
      <path d="M15.5 12a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0"/>
    </g>
  </svg>
}

export function Icon(props: { name: string } & IconPropsBase): ReactElement {
  switch (props.name) {
    case "star":
      return <Star {...props}/>;
    case "favorite":
      return <Favorite {...props}/>;
    case "settings":
      return <Settings {...props}/>;
    case "play":
      return <Play {...props}/>;
    case "stop":
      return <Stop {...props}/>;
    case "info":
      return <Info {...props}/>;
  }
  return null;
}